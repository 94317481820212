import FlagService from "@/services/resources/FlagService";

const serviceFlag = FlagService.build();

let fetchFlagsPromise = null;

export default {
  async getAllFlags({ commit, getters }) {
    if (getters.flags.length > 0) {
      return getters.flags;
    }

    if (fetchFlagsPromise) {
      return fetchFlagsPromise;
    }

    try {
      fetchFlagsPromise = serviceFlag.read("feature-flags");

      const allFlags = await fetchFlagsPromise;
      commit("setAllflags", allFlags);

      return allFlags;
    } catch (error) {
      console.error("Failed to fetch flags:", error);
      throw error; 
    } finally {
      fetchFlagsPromise = null;
    }
  },

  async getUniqueFlag({ dispatch, getters }, findFlag) {
    const flags = getters.flags;
    if (flags.length > 0 && flags.includes(findFlag)) {
      return true;
    }

    await dispatch("getAllFlags");
    return getters.flags.includes(findFlag);
  },
};