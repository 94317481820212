import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "@/store/index";
import { replace } from "feather-icons";
import TokenService from "@/services/resources/TokenService";
import Cookies from "js-cookie";
import { clarity } from "clarity-js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  duplicateNavigationPolicy: 'ignore'
});

router.afterEach((to) => {
  clarity.event('pageView', {
    page: to.fullPath
  })
})

export default router;
