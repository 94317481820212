import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs';

if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_RUM_STATUS == 'enabled') {  
  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'adm',
    env: process.env.VUE_APP_ENVIRONMENT,
    version: process.env.VUE_APP_COMMIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT)) {  
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'adm',
    forwardErrorsToLogs: true,
    version: process.env.VUE_APP_COMMIT_SHA,
    sessionSampleRate: 100,
    env: process.env.VUE_APP_ENVIRONMENT,
    trackInteractions: true,
  });
}

import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTheMask from "vue-the-mask";
import components from "./components/index";
import infiniteScroll from "vue-infinite-scroll";
import VueLoading from "vue-loading-overlay";
import VueObserveVisibility from "vue-observe-visibility";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { date, datetime, datetimem } from "./filters/date";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueEllipseProgress from "vue-ellipse-progress";
import { show, hide } from './grModal/index';
import { toast } from './grToast/index';
Vue.use(VueEllipseProgress);

import BaseView from "./template/BaseView.vue";

Vue.component("BaseView", BaseView);

const moment = require("moment");
require("moment/locale/pt");

Vue.use(require("vue-moment"), {
  moment,
});
import InfiniteScroll from "v-infinite-scroll";

import "v-infinite-scroll/dist/v-infinite-scroll.css";

Vue.use(InfiniteScroll);
Vue.directive('focus', {inserted: function (el) {el.focus();},});
Vue.use(VueObserveVisibility);
Vue.use(VueLoading);
Vue.use(infiniteScroll);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Validator.localize("pt_BR", pt_BR);

Vue.filter("date", date);
Vue.filter("datetime", datetime);
Vue.filter("datetimem", datetimem);


Vue.prototype.$grModal = { show, hide };


for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey]);
}

// if (process.env.VUE_APP_TITLE == "GDigital") {
//   Vue.config.errorHandler = function (err, vm, info) {};
// }

router.afterEach(() => {
  window.document.dispatchEvent(new CustomEvent("vueRouteChangeSuccess"));
});


export const EventBus = new Vue();

// install the CanvasJS Vuejs Chart Plugin

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created(){
    Vue.prototype.$grToast = {
      toast: (content, toastInfos) => toast(this.$children[0], content, toastInfos)
    };
  }
}).$mount("#app");
