import { clarity } from "clarity-js";

export function useClarity() {
  async function initClarity() {
    clarity.consent();

    clarity.start({
      projectId: process.env.VUE_CLARITY_ID,
      upload: "https://m.clarity.ms/collect",
      track: true,
      content: true,
      throttleDom: false,
      delayDom: 2000,
      longTask: 2000,
    });
  }

  return {
    initClarity,
  };
}
