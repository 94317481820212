import { datadogLogs } from '@datadog/browser-logs';

const canUseDatadog = ['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT);


export function useDatadog() {
    function info(title, data = {}) {
        if(!canUseDatadog){
            return;
        }
        datadogLogs.logger.info(title, data);        
    }

    function error(title, data = {}) {        
        if(!canUseDatadog){
            return;
        }
        datadogLogs.logger.error(title, data);
    }

    function warning(title, data = {}) {
        if(!canUseDatadog){
            return;
        }
        datadogLogs.logger.warn(title, data);
    }

    return {
        info,
        error,
        warning
    };
}